try {
    window.isMobile = require("ismobilejs").default
} catch (e) {}

function isDeviceSuported() {
    if (isMobile().phone && !isMobile().tablet ) {
        window.location.href = '/supported-devices'
    }else{
        console.log('Supported Device')
    }
};

export default isDeviceSuported