/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

try {
    window.$ = window.jQuery = require("jquery");
    window.isDeviceSuported = require("./supported_devices.js").default;
    require("bootstrap");
} catch (e) {}

$(document).ready(() => {
    $("#cookie-modal-consent").on("click", function () {
        //Disable Matomo here
        //window["ga-disable-UA-154977561-1"] = false;
        let d = new Date();
        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = "cornerstone-cookie-consent=2;" + expires + ";path=/";
    });
    $("#cookie-modal-settings-consent").on("click", function () {
        let value = 1;
        if ($("#cookie-analytics-consent-check:checked").length === 1) {
            //Disable Matomo here
            // window["ga-disable-UA-154977561-1"] = false;
            value = 2;
        }
        let d = new Date();
        d.setTime(d.getTime() + 365 * 24 * 60 * 60 * 1000);
        let expires = "expires=" + d.toUTCString();
        document.cookie = "cornerstone-cookie-consent=" + value + ";" + expires + ";path=/";
    });
    check_cookie();
    check_analytics_cookie();
    $("#cookie-modal-settings-btn").on("click", function () {
        $("#cookie-modal").modal("hide");
        let exists = false;
        let name = "cornerstone-cookie-consent=2";
        let ca = document.cookie.split(";");
        for (var i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == " ") {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                exists = true;
            }
        }
        if (exists) {
            $("#cookie-analytics-consent-check").attr("checked", true);
        } else {
            $("#cookie-analytics-consent-check").removeAttr("checked");
        }
        $("#cookie-settings-modal").modal("show");
        $(".modal-backdrop").removeClass("modal-backdrop");
        $(".modal-open").removeClass("modal-open");
        $("body").css("padding-right", 0);
    });
});

function check_analytics_cookie() {
    let exists = false;
    let name = "cornerstone-cookie-consent=2";
    let ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            exists = true;
        }
    }
    if (exists) {
        //Disable matomo cookies
        // window["ga-disable-UA-154977561-1"] = false;
    }
}

function check_cookie() {
    let not = true;
    let name = "cornerstone-cookie-consent=";
    let ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == " ") {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            not = false;
        }
    }
    console.log(not);
    if (not) {
        $("#cookie-modal").modal("show");
        $(".modal-backdrop").removeClass("modal-backdrop");
        $(".modal-open").removeClass("modal-open");
        $("body").css("padding-right", 0);
    }
}
